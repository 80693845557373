<template>
  <form action="" @submit.stop.prevent="() => {}">
    <div class="form-row desktop_form" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <select-theme
          @changeDestination="destChanged"
          @optionSelect="destSelect"
          :list="destinationList"
        />
      </div>
      <div class="form-group col-md-3">
        <hotel-theme
          :destination="destinationCityName"
          :hotelListShow="destinationChanged"
          @changeHotel="hotelChanged"
          @optionSelect="hotelSelect"
          :list="hotelList"
        />
      </div>
      <div class="form-group col-md-3">
        <datepicker-range
          :calendarShow="destinationChanged && hotelListChanged"
          @finalDateSelect="finalDateSelect"
          @dates="chooseDate"
        ></datepicker-range>
      </div>
      <div class="form-group col-md-2">
        <occupancy-pax @changeBodyCount="changeCount" :dropdownShow="dropdownShow"/>
      </div>
      <div class="form-group col-md-1 d-flex">
        <button class="btn flex-fill btn-search" @click="submitSearch" :disabled="searchDisable">
          {{ $t("search-tab.search") }}
          <b-spinner small v-if="isLoading"></b-spinner>
          <i class="fas fa-search" v-else></i>
        </button>
      </div>
    </div>
    <div class="card boxSideSearch mobile_form border-0" v-else>
        <div class="form-group col-12">
          <select-theme
            @changeDestination="destChanged"
            @optionSelect="destSelect"
            :list="destinationList"
          />
        </div>
        <div class="form-group col-12">
          <hotel-theme
            :destination="destinationCityName"
            :hotelListShow="destinationChanged"
            @changeHotel="hotelChanged"
            @optionSelect="hotelSelect"
            :list="hotelList"
            :multiple="true"
          />
        </div>
        <div class="form-group col-12">
          <div class="input-group">
            <datepicker-range
              :calendarShow="destinationChanged && hotelListChanged"
              @dates="chooseDate"
              type="side"
            ></datepicker-range>
          </div>
        </div>
        <div class="form-group row">
          <div class="form-group col-4">
            <b-form-select class="form-control" v-model="countAdult">
              <template #first>
                <b-form-select-option :value="0" disabled>{{
                  $t("search-tab.adult")
                }}</b-form-select-option>
              </template>
              <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
            </b-form-select>
          </div>
          <div class="form-group col-4">
            <b-form-select class="form-control" v-model="countChild">
              <template #first>
                <b-form-select-option :value="0" disabled>{{
                  $t("search-tab.child")
                }}</b-form-select-option>
              </template>
              <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
            </b-form-select>
          </div>
          <div class="form-group col-4">
            <b-form-select class="form-control" v-model="countInfant">
              <template #first>
                <b-form-select-option :value="0" disabled>{{
                  $t("search-tab.infant")
                }}</b-form-select-option>
              </template>
              <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
            </b-form-select>
          </div>
        </div>
        <div class="form-group col-12 d-flex">
          <button
            class="flex-fill btn-search"
            @click="submitSearch"
            :disabled="searchDisable"
          >
            {{ $t("search-tab.search") }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
    </div>

    <Breadcrumb :data="linkDataSearch" v-if="linkDataSearch && breadcrumb==='breadcrumb'" :destination="getBreadcrumb" :page="`search`"/>
  </form>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';
import TemplateSearchTab from './TemplateSearchTab';

export default {
  name: 'SearchTabTheme0',
  mixins: [TemplateSearchTab],
  components: {
    OccupancyPax: () => import('@/components/atoms/occupancyPax/OccupancyPaxTheme2'),
    SelectTheme: () => import('@/components/atoms/searchSelect/SearchSelectTheme2'),
    HotelTheme: () => import('@/components/atoms/multipleSelect/MultipleSelectTheme2'),
    DatepickerRange: () => import('@/components/atoms/customDatepickerRange/CustomDatepickerRangeTheme2'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    BFormSelect,
    BFormSelectOption,
    BSpinner,
  },
};
</script>

<style scoped lang="scss">
.btn-search{
  border-radius: 30px;
  border: 1px solid #FF9500 !important;
  background-color: #FF9500 !important;
  color: #ffffff;
  font-size: 17px;
  font-weight: 500;
  padding: 14px;
  i {
    margin-right: 10px;
  }

  &:disabled{
    background-color: #ff3131 !important;
    border-color: #e9ecef !important;
  }
}

.mobile_form{
  display: block;
  .form-group{
    margin: 0.5rem 0rem;
    position: relative;
  }
}

@media (max-width: 476px)  {
  .mobile_form{
    .form-group{
      &.col-12, &.col-4{
        padding: 0px 5px;
      }
    }
  }
}

.desktop_form{
  .form-group{
    margin-bottom: 0px;
  }

  .btn-search{
    padding: 0px;
    line-height: 24px;
    opacity: 1;
    i {
      margin-right: 0px;
    }
  }
}

</style>
